import React from "react";

const isProduction = process.env.NODE_ENV !== "development";

const GlobalButterCMSContentContext = React.createContext({});

export function GlobalButterCMSContentContextProvider({value, children}) {
  if (!isProduction && typeof window !== "undefined") {
    console.log("Global page content:", value);
  }

  return (
    <GlobalButterCMSContentContext.Provider value={value}>
      {children}
    </GlobalButterCMSContentContext.Provider>
  );
}

export default GlobalButterCMSContentContext;
