import previewDeployment from "./preview-deployment";
import Butter from "buttercms";

export const butter = Butter(process.env.BUTTERCMS_API_KEY, false, 30000);

function logButterError(error, errorMessage) {
  console.error(
    `${error?.request?.method} ${error?.config?.url}.`,
    error?.response?.data?.detail || errorMessage
  );
}

// if previewDeployment is true,
// force all Butter APIs into preview mode.
// previewDeployment should never be active in production.
// console.log('Preview deployment: ', previewDeployment)

export async function getPreviewPostBySlug(slug) {
  const postResponse = await butter.page.retrieve("news_item", slug, {
    preview: 1,
  });
  return postResponse.data.data;
}

export async function getPageByTitle(title, preview) {
  const isPreview = preview || previewDeployment ? 1 : 0;

  try {
    const postResponse = await butter.page.retrieve("*", title, {
      preview: isPreview,
      levels: 10,
    });

    return postResponse.data.data;
  } catch (error) {
    logButterError(error, `Page not found: ${title}`);
    return {};
  }
}

export async function getCollectionByTitle(title, preview) {
  const isPreview = preview || previewDeployment ? 1 : 0;

  try {
    const postResponse = await butter.content.retrieve([`${title}`], {
      page: "1",
      page_size: "10",
      levels: "4",
      preview: isPreview,
    });
    return postResponse.data.data[title];
  } catch (error) {
    logButterError(error, `Collection not found: ${title}`);
    return [];
  }
}

export async function getCollections(titles, preview) {
  const isPreview = preview || previewDeployment ? 1 : 0;

  try {
    const postResponse = await butter.content.retrieve(titles, {
      levels: "3",
      preview: isPreview,
    });
    return postResponse.data.data;
  } catch (error) {
    logButterError(error, `Collections not found: ${titles}`);
    return [];
  }
}

export async function getAllByPageType(page_type, preview) {
  const isPreview = preview || previewDeployment ? 1 : 0;

  try {
    const postResponse = await butter.page.list(page_type, {
      preview: isPreview,
      page_size: 100,
    });

    return postResponse.data.data;
  } catch (error) {
    logButterError(error, `Page type not found: ${page_type}`);
    return [];
  }
}

export async function getPageByTitleAndType(title, type, preview, levels) {
  const isPreview = preview || previewDeployment ? 1 : 0;

  return butter.page
    .retrieve(type, title, {
      preview: isPreview,
      levels,
    })
    .then((response) => {
      if (response && response.data && response.data.data) {
        return response.data.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      logButterError(error, `Page title and type not found: ${type}-${title}`);
      return {};
    });
}
