import React, {useEffect} from "react";
import {useRouter} from "next/router";
import Script from "next/script";

const PERSISTED_QUERY_PARAMS = [
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "utm_aid",
];

function getStoredQueryParam(queryParamName) {
  try {
    return sessionStorage.getItem(queryParamName);
  } catch (error) {
    console.error(error);
    return;
  }
}

function storeQueryParam(queryParamName, queryParamValue) {
  try {
    if (queryParamValue) {
      // NOTE: any reason these can't be stored under a single key?
      sessionStorage.setItem(queryParamName, queryParamValue);
    }
  } catch (error) {
    console.error(error);
  }
}

export default function Analytics() {
  const router = useRouter();

  // Ensure UTM params are saved in local storage and
  // appended to the URL on subsequent page loads
  useEffect(() => {
    function handleOnRouteChange() {
      const storedQueryParams = PERSISTED_QUERY_PARAMS.reduce(
        (acc, queryParamName) => {
          const storedValue = getStoredQueryParam(queryParamName);
          return storedValue
            ? {
                ...acc,
                [queryParamName]: storedValue,
              }
            : acc;
        },
        {}
      );

      // Check if need to update URL
      const needToUpdateRoute = PERSISTED_QUERY_PARAMS.some(
        (queryParamName) =>
          storedQueryParams[queryParamName] && !router.query[queryParamName]
      );

      // Store to local storage
      PERSISTED_QUERY_PARAMS.forEach((queryParamName) => {
        storeQueryParam(queryParamName, router.query[queryParamName]);
      });

      if (needToUpdateRoute) {
        router.replace(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              ...storedQueryParams,
            },
          },
          undefined,
          {shallow: true}
        );
      }
    }

    router.events.on("routeChangeComplete", handleOnRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleOnRouteChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Script id="click-cease">{`
          var script = document.createElement('script'); script.async = true;
          script.type = 'text/javascript'; var target =
          'https://www.clickcease.com/monitor/stat.js'; script.src =
          target;var elem = document.head;elem.appendChild(script);
      `}</Script>
      {process.env.SEGMENT_WRITE_KEY && (
        <Script id="segment-script">
          {`
            !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="YOUR_WRITE_KEY";analytics.SNIPPET_VERSION="4.15.2";
            analytics.load("${process.env.SEGMENT_WRITE_KEY || ""}");
            analytics.page();
            }}();
          `}
        </Script>
      )}
      {process.env.BRANCH_IO_KEY && (
        <Script id="branch-script">
          {`
            (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData".split(" "), 0);
            branch.init("${process.env.BRANCH_IO_KEY}");
          `}
        </Script>
      )}
      {process.env.APPSFLYER_WEB_DEV_KEY && (
        <Script id="appsflyer-script">
          {`
            !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
              (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
              t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
              o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
              p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","pba",{pba: {webAppId: "${process.env.APPSFLYER_WEB_DEV_KEY}", measurementStatus:true}})
          `}
        </Script>
      )}
      {process.env.NEXT_PUBLIC_ITERATE_API_KEY && (
        <Script id="iterate-surveys">
          {`
            window.iterateSettings = {
              apiKey: "${process.env.NEXT_PUBLIC_ITERATE_API_KEY}"
            };

            (function(i,t,e,r,a){if(t.getElementById(r)){return}
            i.IterateObjectName=a;var z=function(){z.c(arguments)};z.q=[];
            z.c=function(args){z.q.push(args)};i[a]=z;
            function l() {var js,fjs=t.getElementsByTagName(e)[0];js=t.createElement(e);js.id=r;js.async=1;js.src="https://platform.iteratehq.com/loader.js";fjs.parentNode.insertBefore(js,fjs)};
            if(t.readyState==="complete") {l();} else if(i.attachEvent) {i.attachEvent('onload', l);} else{i.addEventListener('load', l, false);}
            }(window, document,'script','iterate-js','Iterate'));
          `}
        </Script>
      )}
      <Script
        id="appsFlyerSmartScript"
        type="text/javascript"
        src={
          process.env.IS_PRODUCTION
            ? "https://www.autonomy.com/scripts/appsflyer-smart-script.js"
            : "/scripts/appsflyer-smart-script.js"
        }
        strategy="beforeInteractive"
        defer={false}
      ></Script>
      <noscript>
        <a href="https://www.clickcease.com" rel="nofollow">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="https://monitor.clickcease.com/stats/stats.aspx"
            alt="ClickCease"
          />
        </a>
      </noscript>
    </React.Fragment>
  );
}
