export const TESLA_VEHICLE = {
  make: "Tesla",
  model: "Model 3",
};

export const TYPE_THEME = {
  BLUE: "blue",
  DARK: "dark",
  LIGHT: "light",
  TRANSPARENT: "transparent",
};

export const PAGE_SLUG = {
  WHY_SUBSCRIPTION: "why-subscription",
  NOT_FOUND: "404",
  ABOUT_US: "about-us",
  BLOG_AGGREGATOR: "blog",
  OUR_TEAM: "our-team",
  CAREER: "careers",
  MODEL_Y_WAITLIST: "model-y-waitlist",
  MODEL_3_VDP: "/",
  MODEL_Y_VDP: "tesla/model-y",
  VF8_VDP: "vinfast/vf8",
  PRESS: "press",
  VEHICLE_LISTING: "vehicles",
};

export const DEFAULT_LD_USER = {key: "web_user"};

export const BUTTER_PAGE_TITLE = {
  HOME: "home-reimagined",
  BLOG_AGGREGATOR: "blog-aggregator",
  MODEL_Y_WAITLIST: "waitlist",
  MODEL_Y_VDP: "model-y-vdp",
  VF8_VDP: "vf8-vdp",
  REFUNDABLE_MODEL: "refundable-modal",
  RESERVATION_TERMS: "reservationterms",
  TOS: "tos",
  PRIVACY: "privacy",
  COMPARISON_TABLE: "comparison-table",
  HOW_IT_WORKS_MODEL: "how-it-works-modal",
  LEGAL: "legal",
  GAS_VS_EV: "gas-vs-ev-modal",
  PRESS: "press",
  VEHICLE_LISTING: "vehicles",
};

export const BUTTER_PAGE_TYPE = {
  BLOG: "blog",
  VEHICLE: "vehicle",
  PARTNER: "partner",
};

export const BUTTER_COLLECTION_TITLE = {
  VDP_NAV_CONTENT: "model_3_vdp_-_main",
  MAIN_NAV_CONTENT: "main_menu_-_main",
  VDP_NAV_CONTENT_MOBILE: "model_3_vdp_-_mobile",
  MAIN_NAV_CONTENT_MOBILE: "main_-_mobile",
  MODEL_Y_WAITLIST_NAV_CONTENT_MOBILE: "model_y_-_mobile",
  MAIN_FOOTER_CONTENT: "footer_-_main",
  VDP_FOOTER_CONTENT: "footer_-_vdp",
  VDP_PRESS_CONTENT: "press-vdp",
  MAIN_PRESS_CONTENT: "press_-_main",
  COMPARISON_TABLE: "comparison_table",
  GAS_VS_EV: "gas_vs_ev_modal",
  VEHICLE_SPECS_COMPARE: "vehicle_specs_compare_modal",
  BLOG_CATEGORIES: "blog_categories",
};
