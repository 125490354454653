import {useEffect, useState} from "react";
import {
  CustomerServicePromiseClient,
  SubscriptionServicePromiseClient,
  NotificationServicePromiseClient,
  BillingServicePromiseClient,
} from "@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb";
import {SendDeepLinkRequest as OldSendDeepLinkRequest} from "@nxcr-org/web-api-interface/lib/notification_service_pb";
import {
  ListProgramsRequest,
  CreateWebReservationRequest,
} from "@nxcr-org/web-api-interface/lib/subscription_service_pb";
import {ListProgramsRequest as BillingServiceListProgramRequest} from "@nxcr-org/web-api-interface/lib/billing_service_pb";
import {
  ExistsByPhoneRequest,
  SendDeepLinkRequest,
} from "@nxcr-org/web-api-interface/lib/customer_service_pb";
import useIsMounted from "../components/hooks/useIsMounted";
import Cookies from "js-cookie";

const target = process.env.NEXT_PUBLIC_FLEET_LISTING_URL;

const billingServiceClient = new BillingServicePromiseClient(target);
const customerServiceClient = new CustomerServicePromiseClient(target);
const subscriptionClient = new SubscriptionServicePromiseClient(
  target,
  null,
  null
);

const notificationClient = new NotificationServicePromiseClient(
  target,
  null,
  null
);

export const PROGRAM_VEHICLE_NAMES = {
  TESLA_MODEL_3: "TeslaModel3",
  TESLA_MODEL_Y: "TeslaModelY",
  VINFAST_VF_8_CITY_ECO: "VinFastVf8Eco",
  VINFAST_VF_8_CITY_PLUS: "VinFastVf8Plus",
};

/**
 * Absolute there is a better way to do this and it should be linked to the api or to butter or something else.
 */
export const ReservationDefaultOptions = {
  startFee: 3000,
  monthly: 450,
};

function sanitizePhoneNumber(phoneNumber) {
  const phoneNumberStr = phoneNumber.replace(/\D/g, ""); // strip non-digits

  if (!phoneNumberStr.startsWith("+1")) {
    return `+1${phoneNumberStr}`;
  }

  return phoneNumberStr;
}

// TODO - move to it's own service file
const ERROR_CODE_NON_EXISTING_CUSTOMER = 5;
export function checkIfPhoneExists(phoneNumber) {
  const request = new ExistsByPhoneRequest();

  request.setPhone(sanitizePhoneNumber(phoneNumber));

  return customerServiceClient
    .existsByPhone(request)
    .then((response) => {
      return response.toObject();
    })
    .then(() => {
      return Promise.resolve({exists: true});
    })
    .catch((err) => {
      if (err && err.code === ERROR_CODE_NON_EXISTING_CUSTOMER) {
        return Promise.resolve({
          exists: false,
        });
      }

      console.log({err});
    });
}

export function createWebReservation(data) {
  const request = new CreateWebReservationRequest();

  request.setProgramId(data.programId);
  request.setFirstName(data.firstName);
  request.setLastName(data.lastName);
  request.setEmail(data.email);
  request.setPhone(sanitizePhoneNumber(data.mobileNumber));
  request.setStartFee(data.startFee);
  request.setMonthlyRate(data.monthlyRent);
  request.setPriceTermMonths(data.priceTermMonths);

  if (data.zip) {
    request.setBillingPostal(data.zip);
  }

  if (data.cardNumber) {
    request.setCardholderName(data.cardHolderName);
    request.setCardNumber(data.cardNumber.replace(/\s+/g, "")); // remove extra spaces
    request.setCardExpiration(data.cardExp);
    request.setCardValidation(data.cardCVV.replace(/\s+/g, ""));
    request.setBillingPostal(data.cardZip);
  }

  if (window.analytics?.user) {
    request.setTrackingId(window.analytics.user().anonymousId());
  }

  return subscriptionClient.createWebReservation(request);
}

export function createReservation(data) {
  return getTeslaModel3Program().then((tesla3Program) => {
    const programId = tesla3Program.id;

    const request = new CreateWebReservationRequest();

    const startFee = !!data.startFee ? data.startFee * 100 : 3000 * 100;
    const monthlyFee = !!data.monthlyFee ? data.monthlyFee * 100 : 450 * 100;

    request.setProgramId(programId);
    request.setFirstName(data.firstName);
    request.setLastName(data.lastName);
    request.setCardholderName(data.cardHolderName);
    request.setEmail(data.email);
    request.setStartFee(startFee);
    request.setMonthlyRate(monthlyFee);
    request.setPriceTermMonths(data.priceTermMonths);

    if (data.cardNumber) {
      request.setCardNumber(data.cardNumber.replace(/\s+/g, "")); // remove extra spaces
      request.setCardExpiration(data.cardExp);
      request.setCardValidation(data.cardCVV.replace(/\s+/g, ""));
    }
    request.setPhone(sanitizePhoneNumber(data.mobileNumber));
    request.setBillingPostal(data.cardZip);

    if (window.analytics?.user) {
      request.setTrackingId(window.analytics.user().anonymousId());
    }

    return subscriptionClient.createWebReservation(request);
  });
}

function listPrograms() {
  const request = new ListProgramsRequest();

  return subscriptionClient.listPrograms(request, null).then((response) => {
    return response.toObject();
  });
}

export function getTeslaModel3Program() {
  return listPrograms().then((programs) => {
    return programs.programsList.find((program) => {
      return program.name === "TeslaModel3";
    });
  });
}

export function sendDeepLink(phoneNumber) {
  const request = new SendDeepLinkRequest();

  const marketingMetaData = new SendDeepLinkRequest.MarketingMetaData();

  request.setPhone(sanitizePhoneNumber(phoneNumber));
  marketingMetaData.setFacebookId(Cookies.get("_fbp"));
  marketingMetaData.setSegmentAnonymousId(
    window?.analytics?.user()?.anonymousId()
  );
  marketingMetaData.setAppsflyerUserId(Cookies.get("afUserId"));
  request.setMarketingData(marketingMetaData);

  return customerServiceClient
    .createCustomerAndSendDeepLink(request, null)
    .then((response) => response.toObject())
    .catch((error) => {
      const oldRequest = new OldSendDeepLinkRequest();
      oldRequest.setType(OldSendDeepLinkRequest.DeepLinkType.INSTALL);
      oldRequest.setPhone(sanitizePhoneNumber(phoneNumber));

      return notificationClient
        .sendDeepLink(oldRequest)
        .then((response) => response.toObject());
    })
    .catch((error) => {
      return Promise.resolve();
    });
}

export function listProgramOfferings() {
  const request = new BillingServiceListProgramRequest();

  return billingServiceClient
    .listProgramOfferings(request)
    .then((res) => res.toObject());
}

export function useListProgramPricingOptions(
  vehicleName = PROGRAM_VEHICLE_NAMES.TESLA_MODEL_3
) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [program, setProgram] = useState(null);

  useEffect(() => {
    setLoading(true);

    listProgramOfferings()
      .then(({programsList}) => {
        const program = programsList.find(({name}) => name === vehicleName);
        const pricingOptionsList = (
          program.pricingOptionsList?.[0]?.pricingOptionsList || []
        ).map((pricingOption) => ({
          monthly: pricingOption.monthlyRent,
          ...pricingOption,
        }));

        if (isMounted.current) {
          const sortedOptions = pricingOptionsList
            .slice(0)
            .sort(({monthly: A}, {monthly: B}) => A - B);
          setPricingOptions(sortedOptions);
          setProgram(program);
          setLoading(false);
        }
      })
      .catch(() => {
        console.log("ERROR: fetching listProgramOfferings");
      });
  }, [isMounted, vehicleName]);

  return {pricingOptions, program, loading};
}
