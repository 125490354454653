import React, {useCallback, useEffect, useRef, useMemo, useState} from "react";
import {
  useListProgramPricingOptions,
  PROGRAM_VEHICLE_NAMES,
} from "../../lib/reservation-service";

function getSafeMonthlyPayment(pricingOptions, newMonthlyPayment) {
  if (pricingOptions.find(({monthly}) => monthly / 100 === newMonthlyPayment)) {
    return newMonthlyPayment;
  }
  return pricingOptions[0].monthly / 100;
}

function getStartFeeFromMonthlyPayment(pricingOptions, monthlyPayment) {
  return (
    pricingOptions.find(({monthly}) => monthly / 100 === monthlyPayment)
      .startFee / 100
  );
}

const PaymentReservationContext = React.createContext({
  isInitialized: false,
  actualLowestPricingOption: {},
  lowestPricingOption: 550,
  pricingOptions: [],
  state: {},
  selectedPricingOption: null,
  setColor: () => {},
  setMonthlyPayment: () => {},
  setSelectedPricingOption: () => {},
  setVehicleName: () => {},
  vehicleName: PROGRAM_VEHICLE_NAMES.TESLA_MODEL_3,
  program: null,
});

const LOWEST_MONTHLY_PAYMENT = 490;

export function PaymentReservationProvider({
  children,
  vehicleName: initialVehicleName = PROGRAM_VEHICLE_NAMES.TESLA_MODEL_3,
}) {
  const [vehicleName, setVehicleName] = useState(initialVehicleName);

  const [color, setColor] = useState("white");
  const [monthlyPayment, setMonthlyPayment] = useState(LOWEST_MONTHLY_PAYMENT); // NOTE: hardcoded to avoid showing NaN
  const [startFee, setStartFee] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  const hadStoredValue = useRef(false);
  const {pricingOptions, program, loading} =
    useListProgramPricingOptions(vehicleName);
  const [selectedPricingOption, setSelectedPricingOption] = useState();

  const lowestPricingOption = useMemo(() => {
    const sortedPriceOptions = pricingOptions
      ?.slice(0)
      .sort(({monthly: A}, {monthly: B}) => A - B);
    return sortedPriceOptions?.[0];
  }, [pricingOptions]);

  const handleSetMonthlyPayment = useCallback(
    (monthlyPayment) => {
      const safeMonthlyPayment = getSafeMonthlyPayment(
        pricingOptions,
        monthlyPayment
      );
      setMonthlyPayment(safeMonthlyPayment);
      setStartFee(
        getStartFeeFromMonthlyPayment(pricingOptions, safeMonthlyPayment)
      );
    },
    [setMonthlyPayment, setStartFee, pricingOptions]
  );

  useEffect(() => {
    if (!loading && !hadStoredValue.current && pricingOptions.length > 0) {
      handleSetMonthlyPayment(
        pricingOptions[pricingOptions.length - 1].monthly / 100
      );
      setIsInitialized(true);
    }
  }, [loading, pricingOptions, handleSetMonthlyPayment]);

  useEffect(() => {
    if (isInitialized) {
      setSelectedPricingOption(pricingOptions?.[0]);
    }
  }, [pricingOptions]);

  const lowestMontlyPayment = lowestPricingOption?.monthly / 100;
  return (
    <PaymentReservationContext.Provider
      value={{
        isInitialized,
        actualLowestPricingOption: lowestPricingOption,
        lowestPricingOption: isNaN(lowestMontlyPayment)
          ? LOWEST_MONTHLY_PAYMENT
          : lowestMontlyPayment,
        pricingOptions,
        state: {
          color,
          monthlyPayment,
          startFee,
        },
        selectedPricingOption,
        setColor,
        setMonthlyPayment: handleSetMonthlyPayment,
        setSelectedPricingOption,
        setVehicleName,
        vehicleName,
        program,
      }}
    >
      {children}
    </PaymentReservationContext.Provider>
  );
}

export default PaymentReservationContext;
